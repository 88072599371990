import request from '@/http.js';
/*****************************门店统计概况*****************************/
/**
 * @description 门店统计概况
 * @param {Object} param data {Object} 传值参数
 */
 export function houseOverviewApi (param) {
    return request.post(
        '/admin.statistics/warehouse',
        param
    )
}

/*****************************管理员统计概况*****************************/
/**
 * @description 统计概况
 * @param {Object} param data {Object} 传值参数
 */
 export function adminOverviewApi (param) {
    return request.post(
        '/admin.statistics/home',
        param
    )
}

/**
 * @description 销售统计
 * @param {Object} param data {Object} 传值参数
 */
export function saleApi (param) {
    return request.post(
        '/admin.sales/sales',
        param
    )
}
/**
 * @description 销售排行
 * @param {Object} param data {Object} 传值参数
 */
export function salesRankingApi (param) {
    return request.post(
        '/admin.sales/top',
        param
    )
}
/**
 * @description 销售对比
 * @param {Object} param data {Object} 传值参数
 */
export function salesStatisticsApi (param) {
    return request.post(
        '/admin.sales/salesYoyMom',
        param
    )
}
/**
 * @description 销售清单
 * @param {Object} param data {Object} 传值参数
 */
export function salesListApi (param) {
    return request.post(
        '/admin.sales/salesList',
        param
    )
}
